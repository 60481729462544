import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../layout/layout";
import { StaticImage } from "gatsby-plugin-image";

export const query = graphql`
    query HelpQuery {
        allSanityHelp(sort: { order: ASC, fields: help_id }) {
            edges {
                node {
                    content {
                        children {
                            text
                        }
                    }
                    help_id
                    id
                    title
                    image {
                        asset {
                            gatsbyImageData
                        }
                    }
                    url {
                        current
                    }
                }
            }
        }
    }
`;

const Help = ({ data }) => {
    useEffect(() => {
        if (data.allSanityHelp) {
            document.body.classList.add("ways-to-help");
        }
    });

    var h1 = {
        color: "#faf75e",
        backgroundColor: "#282828",
        fontSize: "3.2rem",
        position: "absolute",
        top: "-50px",
        left: 0,
        fontFamily: "HCo Champion Heavywt",
        textTransform: "uppercase",
        transform: "rotate(4deg)",
        padding: "0 15px",
    };
    console.log(data);
    return (
        <Layout>
            <div>
                <StaticImage src="../images/hero-image.png" alt="Climate image" />
                <div className="container relative mx-auto text-center">
                    <div className="w-full sm:w-2/3 mx-auto pb-16">
                        <h1 style={h1}>Ways you can help</h1>
                    </div>
                </div>
            </div>
            <main id="main" className="p-6">
                <div className="container w-full sm:w-11/12 md:w-5/6 mx-auto flex flex-wrap">
                    {data.allSanityHelp.edges.map((help) => (
                        <div className="w-full sm:w-1/2 p-4">
                            <div className="card help-card p-4">
                                <span>{help.node.help_id}</span>
                                <h2>{help.node.title}</h2>
                                <Link to={help.node.url.current} className="default-link">
                                    Read more {">"}
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </main>
        </Layout>
    );
};

export default Help;
